import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToHours',
  standalone: true,
})
export class TimeToHoursPipe implements PipeTransform {
  transform(timeString: string | undefined): string {
    if (!timeString) return '';
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    /* eslint-disable @typescript-eslint/typedef */
    const totalHours = hours + minutes / 60 + seconds / 3600;
    return totalHours.toFixed(2);
  }
}
